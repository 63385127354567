import React from "react"
import SvgIcon from "../image/SvgIcon"
import classNames from "classnames"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import * as styles from "./subheaderListLinks.module.scss"

const SubheaderListLinks = ({ title, subtitle, links, columns, isMobile }) => (
  <div
    className={styles.subheaderListLinks}
    style={{ "--subheader-list-width": columns === "one" ? "400px" : undefined }}>
    <div>
      <div className={styles.title}>{title}</div>
      {subtitle && <>{subtitle}</>}
    </div>
    <div
      className={classNames(styles.linksContainer, {
        [styles.oneColumn]: isMobile || columns === "one",
        [styles.twoColumns]: !isMobile && columns === "two",
      })}>
      {links.map(link => {
        const newTab = link.newTab ? { target: "_blank" } : null
        const noFollow = { rel: "nofollow noopener noreferrer" }

        return (
          <div key={link._key} className={styles.linkItemContainer}>
            <span className={styles.linkItem}>
              <SvgIcon url={link.icon?.asset?.url} />
              <Link className={styles.link} to={link.url} {...newTab} {...noFollow}>
                {link.label}
              </Link>
            </span>
          </div>
        )
      })}
    </div>
  </div>
)

SubheaderListLinks.propTypes = {
  title: PropTypes.string.isRequired,
  isMobile: PropTypes.bool,
  subtitle: PropTypes.string,
  columns: PropTypes.oneOf(["one", "two"]),
  links: PropTypes.arrayOf(
    PropTypes.shape({
      _key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      type: PropTypes.oneOf(["internal", "external"]).isRequired,
      newTab: PropTypes.bool,
      icon: PropTypes.shape({
        asset: PropTypes.shape({
          url: PropTypes.string,
          originalFilename: PropTypes.string,
          mimeType: PropTypes.string,
        }),
      }),
    })
  ).isRequired,
}

SubheaderListLinks.defaultProps = {
  subtitle: "",
  columns: "one",
  isMobile: false,
}

export default SubheaderListLinks
