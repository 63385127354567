import React from "react"
import cx from "classnames"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import { getHreflang } from "../../helpers/getHreflang"

import Work from "../../../assets/svgs/work.svg"
import Edu from "../../../assets/svgs/edu.svg"
import Totara from "../../../assets/images/totara.png"
import OLMSWork from "../../../assets/images/work.png"
import OLMSEdu from "../../../assets/images/edu.png"

import * as styles from "./eduWorkButton.module.scss"

const isMobile =
  typeof window === "undefined"
    ? false
    : /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)

const EduWorkButton = props => {
  const { description, url, type, className, variant } = props
  const btnClassName = cx(styles.button, styles[type], styles[variant], className)
  const isRounded = variant === "rounded"
  const market = getHreflang()

  const renderBtn = () => {
    if (isRounded) {
      switch (type) {
        case "edu":
          return <img src={OLMSEdu} alt="edu" />
        case "work":
          return <img src={OLMSWork} alt="work" />
        case "totara":
          return <img src={Totara} alt="totara" />
        default:
          return null
      }
    }
    switch (type) {
      case "edu":
        return <Edu />
      case "work":
        return <Work />
      case "totara":
        return <img src={Totara} alt="totara" />
      default:
        return null
    }
  }

  const renderRounded = () => {
    return (
      <Link to={url} className={styles.container}>
        <div className={btnClassName}>
          <div className={cx(styles[type], styles.slideBlock)}>{renderBtn()}</div>
          {!isMobile && (
            <div className={cx(styles[type], styles.descriptionBlock)}>
              <span className={styles.description}>{description}</span>
            </div>
          )}
        </div>
      </Link>
    )
  }

  if (type === "totara" && market === "en-au") return null

  if (isRounded) return renderRounded()

  return (
    <Link to={url} className={cx(styles.button, styles[type])}>
      {renderBtn()}
    </Link>
  )
}

EduWorkButton.propTypes = {
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  url: PropTypes.string,
  type: PropTypes.oneOf(["edu", "work", "totara"]),
  className: PropTypes.string,
  variant: PropTypes.oneOf(["", "rounded"]),
}

EduWorkButton.defaultProps = {
  url: "",
  description: "",
  type: "edu",
  className: "",
  variant: "",
}

export default EduWorkButton
