import { useEffect, useState } from "react"

const getPageType = type => {
  switch (type) {
    case "blogPost":
      return "blog"
    case "cs":
      return "case-studies"
    case "event":
      return "events"
    case "industry":
      return "industries"
    default:
      return ""
  }
}

export const useGenerateLanguageSlug = translations => {
  const [markets, setMarkets] = useState([
    { label: "EN", path: "/" },
    { label: "ES", path: "/es" },
    { label: "AU", path: "/au" },
    { label: "FR", path: "https://fr.openlms.net" },
    { label: "JP", path: "https://jp.openlms.net" },
    { label: "PT", path: "/pt" },
  ])

  useEffect(() => {
    if (!translations?.length || translations[0]?._type === "industry") return

    const _type = getPageType(translations[0]?._type)

    setMarkets(prevMarkets =>
      prevMarkets.map(market => {
        const marketKey = market.label === "EN" ? "en-us" : market.label.toLowerCase()
        const foundTranslation = translations.find(t => t.tabs.content.market === marketKey)

        if (!foundTranslation) return market

        const { parent, slug, category } = foundTranslation.tabs.content

        let newPath =
          market.label === "EN"
            ? `/${parent ? `${parent.tabs.content.slug.current}/` : ""}${slug?.current ?? ""}`
            : `/${market.label.toLowerCase()}/${parent ? `${parent.tabs.content.slug.current}/` : ""}${
                slug?.current ?? ""
              }`

        if (category?.slug?.current) {
          newPath =
            market.label === "EN"
              ? `/${_type}/${category.slug.current}/${slug?.current ?? ""}`
              : `/${market.label.toLowerCase()}/${_type}/${category.slug.current}/${slug?.current ?? ""}`
        }

        return { ...market, path: newPath }
      })
    )
  }, [translations])

  return { markets }
}
