import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"

import * as styles from "./image.module.scss"

const SvgIcon = ({ url, size }) => {
  const [svgContent, setSvgContent] = useState(null)

  const inlineSizeStyle = size ? { "--icon-size": `${size}px` } : {}

  useEffect(() => {
    if (!url) return
    fetch(url)
      .then(res => res.text())
      .then(data => {
        const updatedSvg = data.replace(/fill="[^"]*"/g, "fill=currentColor")
        setSvgContent(updatedSvg)
      })
      .catch(err => console.error("Error fetching SVG:", err))
  }, [])

  return svgContent ? (
    <div className={styles.svgIcon} style={inlineSizeStyle} dangerouslySetInnerHTML={{ __html: svgContent }} />
  ) : null
}

SvgIcon.propTypes = {
  url: PropTypes.string.isRequired,
  size: PropTypes.number,
}

export default SvgIcon
