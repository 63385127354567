import React, { useState, useEffect } from "react"
import BaseBlockContent from "@sanity/block-content-to-react"
import PropTypes from "prop-types"
import cx from "classnames"

import FormattedLink from "../formattedLink/formattedLink"
import Blockquote from "./blockquote.js"
import Tip from "./tip.js"
import Video from "../video/video"
import InlineImage from "./inlineImage"
import HorizontalLine from "./horizontalLine"
import TocManual from "./tocManual"
import Table from "../table/table"
import TocAuto from "./tocAuto"
import CookieButton from "../cookies/cookieButton"
import VideoInline from "../videoInline/videoInline"
import PartnersCarousel from "../partnersCarousel/partnersCarousel"
import Section from "../section/section"
import RichCustomColumns from "./richCustomColumns"
import IframeEmbed from "../iframe/iframeEmbed"

import { slugify } from "../../helpers/slugify"

import * as styles from "./richText.module.scss"

const BlockContent = ({ narrower, blocks, className, enableAutoToc, autoTocTitle, bgColor, theme }) => {
  const serializers = {
    types: {
      block: props => {
        const style = props.node.style || "normal"

        if (/^h\d/.test(style)) {
          const text = props.node.children.map(item => item.text.trim()).join(" ")
          const elementId = slugify(text)
          return React.createElement(style, { id: `${elementId}` }, props.children)
        }

        if (style === "largeText") {
          return <span className="h3">{props.children}</span>
        }
        return BaseBlockContent.defaultSerializers.types.block(props)
      },
      links: props => (
        <div className={props.node.linkPosition || ""}>
          <FormattedLink {...props.node}>{props.node.label}</FormattedLink>
        </div>
      ),
      richTextQuote: props => <Blockquote {...props.node} />,
      tipTextObject: props => <Tip {...props.node} />,
      partnersCarousel: props => (
        <Section className={styles.section}>
          <PartnersCarousel {...props.node} isRichText />
        </Section>
      ),
      richCustomColumns: ({ node }) => <RichCustomColumns {...node} />,
      iframeEmbed: ({ node }) => (
        <Section className={styles.section}>
          <IframeEmbed {...node} />
        </Section>
      ),
      video: props => {
        const data = props.node?.uploadedVideo?.asset

        return (
          <div style={{ maxWidth: "80%", margin: "16px auto" }}>
            {data ? (
              <>
                {<h2 className="text-align-center mb-m">{props.node.title}</h2>}
                <VideoInline {...data} isControl />
              </>
            ) : (
              <Video {...props.node} video={props.node.url} />
            )}
          </div>
        )
      },
      inlineImage: props => <InlineImage {...props.node} />,
      horizontalLine: () => <HorizontalLine />,
      tocManual: props => <TocManual list={props.node.text} title={props.node.tocTitle} />,
      table: props => <Table {...props} />,
      cookieButton: props => {
        if (!props.node?.isShow) return null
        return <CookieButton />
      },
    },
    list: props => {
      const { type } = props
      if (type === "bullet") {
        return <ul>{props.children}</ul>
      } else if (type === "check") {
        return <ul className="check">{props.children}</ul>
      }
      return <ol>{props.children}</ol>
    },
    marks: {
      link: ({ mark, children }) => {
        return (
          <FormattedLink {...mark} bgColor={bgColor} theme={theme}>
            {children}
          </FormattedLink>
        )
      },
      primary: ({ children }) => <strong className="primary">{children}</strong>,
      secondary: ({ children }) => <strong className="secondary">{children}</strong>,
      left: ({ children }) => <span className="text-align-left">{children}</span>,
      center: ({ children }) => <span className="text-align-center">{children}</span>,
      right: ({ children }) => <span className="text-align-right">{children}</span>,
      id: ({ mark, children }) => (
        <span className="anchor" id={mark.id?.current}>
          {children}
        </span>
      ),
      largeText: ({ children }) => <span className="h3">{children}</span>,
    },
  }

  const [headings, setHeadings] = useState([])
  useEffect(() => {
    const hTags = blocks?.filter(item => /^h\d/.test(item.style))
    setHeadings(hTags)
  }, [blocks])

  return (
    <>
      {enableAutoToc && <TocAuto headings={headings} title={autoTocTitle} />}
      <BaseBlockContent
        className={cx(styles.textStyle, narrower && styles.narrowRichtext, className)}
        blocks={blocks || []}
        serializers={serializers}
      />
    </>
  )
}

BlockContent.propTypes = {
  className: PropTypes.string,
  blocks: PropTypes.arrayOf(PropTypes.any),
  bgColor: PropTypes.string,
  theme: PropTypes.string,
}

BlockContent.defaultProps = {
  className: "",
  blocks: [],
  bgColor: "",
  theme: "default",
}

export default BlockContent
