import React from "react"
import PropTypes from "prop-types"

import NavLink from "./navLink"

import * as styles from "./header.module.scss"

const Navigation = ({ links, isSubheader, isMobile }) => {
  return (
    <nav className={styles.navigation}>
      {links.map((link, index) => (
        <NavLink key={link?._key ?? String(index)} isMobile={isMobile} isSubheader={isSubheader} {...link} />
      ))}
    </nav>
  )
}

Navigation.propTypes = {
  links: PropTypes.arrayOf(PropTypes.any),
  isSubheader: PropTypes.bool,
}

Navigation.defaultProps = {
  links: [],
  isSubheader: false,
}

export default Navigation
