import React, { useCallback, useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { FaRegComment } from "react-icons/fa"
import { Link } from "gatsby"
import { useLocation } from "@reach/router"
import { useGenerateLanguageSlug } from "../../../hooks/useGenerateLanguageSlug"
import Slide from "react-reveal/Slide"

import { markets as defaultMarkets } from "../../consts/markets"
import * as styles from "./languageSwitch.module.scss"

function LanguageSwitch({ className, translations }) {
  const [open, setOpen] = useState(false)
  const containerRef = useRef()

  const location = useLocation()
  const { markets } = useGenerateLanguageSlug(translations)

  const handleClick = useCallback(() => {
    setOpen(provided => !provided)
  }, [setOpen])

  const clickListener = useCallback(
    e => {
      if (!containerRef.current?.contains(e.target) && open) {
        setOpen(false)
      }
    },
    [open]
  )

  const tabListener = useCallback(
    e => {
      const isTab = e.which === 9
      if (isTab && open && !containerRef.current?.contains(e.target)) {
        setOpen(false)
      }
    },
    [open]
  )

  useEffect(() => {
    if (!open) {
      const languageIcon = containerRef.current?.firstChild
      const isEqual = document.activeElement.isEqualNode(languageIcon)
      if (isEqual) document.activeElement?.blur()
    }
  }, [open])

  useEffect(() => {
    document.addEventListener("click", clickListener)
    document.addEventListener("keyup", tabListener)
    return () => {
      document.removeEventListener("click", clickListener)
      document.removeEventListener("keyup", tabListener)
    }
  }, [clickListener, tabListener])

  const renderMenu = () => {
    return (
      <Slide when={open} collapse top duration={400}>
        <div className={styles.dropdown}>
          {markets.map(({ label, path }) => {
            return (
              <Link key={path} to={path}>
                {label}
              </Link>
            )
          })}
        </div>
      </Slide>
    )
  }

  const getCurrentMarket = () => {
    const marketKeys = Object.keys(defaultMarkets)
    const currentMarket = marketKeys.find(key => location.pathname.includes(`/${defaultMarkets[key]}`))
    return (currentMarket === "US" ? "EN" : currentMarket) || "EN"
  }

  const currentMarket = getCurrentMarket()

  return (
    <div ref={containerRef} className={className}>
      <div
        className={classNames(styles.languageSwitch, open && styles.active)}
        onClick={handleClick}
        onKeyPress={handleClick}
        tabIndex={0}
        role="button">
        <FaRegComment color="white" size={40} />
        <div className={styles.lang}>{currentMarket}</div>
      </div>
      {renderMenu()}
    </div>
  )
}

LanguageSwitch.propTypes = {
  className: PropTypes.string,
}

LanguageSwitch.defaultProps = {
  className: "",
}

export default LanguageSwitch
